import React from 'react';
import Footer from 'cccisd-footer';
import PropTypes from 'prop-types';
import neda from '../../../images/footer/NEDA.png';
import washu from '../../../images/footer/WashUMed.png';
import shire from '../../../images/footer/Shire.png';
import moedc from '../../../images/footer/MOEDC.png';
import style from './style.css';

const AppFooter = ({ className }) => {
    return (
        <div className={style.footer}>
            <div className="container">
                <div className={style.footerLogos}>
                    <img src={neda} alt="National Eating Disorders Association" />
                    <img
                        src={washu}
                        alt="Washington University in St. Louis School of Medicine"
                        className={style.washu}
                    />
                    <img src={shire} alt="Shire" />
                    <img src={moedc} alt="Missouri Eating Disorders Council" className={style.moedc} />
                </div>
            </div>
            <Footer className={className} />
        </div>
    );
};

AppFooter.defaultProps = {
    className: 'container',
};

AppFooter.propTypes = {
    className: PropTypes.string,
};

export default AppFooter;
