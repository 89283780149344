import React from 'react';
import PropTypes from 'prop-types';
import Header from 'cccisd-header';
import style from './style.css';

const wpUrl = window.cccisd.env.wpUrl;
const Fortress = window.cccisd && window.cccisd.fortress;
const AppHeader = ({ className }) => {
    const manageNav = { url: '/manage', label: 'Manage', activeRegex: 'manage' };

    let primaryNavLinks = [
        { url: '/survey/training', label: 'Training', activeRegex: 'd/hvcrvl1' },
        { url: wpUrl + 'contact', label: 'Contact', activeRegex: 'contact' },
    ];

    if (Fortress.auth() && Fortress.user.acting.role.handle === 'uberadmin') {
        primaryNavLinks.push(manageNav);
    }

    return (
        <Header
            containerClassName={className}
            logoClassName={style.logo}
            logoLink={wpUrl}
            primaryNavLinks={primaryNavLinks}
            showAlertAction={false}
            wrapperClassName={style.wrapper}
            isDarkPrimaryNav
        />
    );
};

AppHeader.defaultProps = {
    className: 'container',
};

AppHeader.propTypes = {
    className: PropTypes.string,
};

export default AppHeader;
