import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import AppHeader from '../../components/AppHeader';
import AppFooter from '../../components/AppFooter';

export default class MainLayout extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
    };

    static defaultProps = {
        className: 'container',
    };

    render() {
        return (
            <div className={style.wrapper}>
                <div className={style.header}>
                    <AppHeader className={this.props.className} noLogoLink />
                </div>
                <div className={style.body}>
                    <div className={this.props.className}>{this.props.children}</div>
                </div>
                <AppFooter className={this.props.className} />
            </div>
        );
    }
}
